<script setup>
import { computed, onMounted } from 'vue';
import { useUi } from '@/composables/use-ui';
import MenuList from '../main-menu/list.vue';

const { windowHeight, windowWidth, toolbarHeightPx, mobileBreakpointPx } = useUi();
const isMobile = computed(() => windowWidth.value < mobileBreakpointPx);
const menuHeight = computed(() => windowHeight.value - toolbarHeightPx);

const menu = [
  {
    type: 'solo',
    id: 'home',
    name: 'Home',
    icon: 'las la-home',
    linkTo: '/dashboard',
  },
  {
    type: 'solo',
    id: 'account-setup',
    name: 'Account Setup',
    icon: 'las la-user',
    linkTo: '/settings/account/personal',
  },
  {
    type: 'solo',
    id: 'booking-setup',
    name: 'Booking Setup',
    icon: 'las la-calendar-alt',
    linkTo: '/settings/booking/setup',
  },
  // {
  //   type: 'solo',
  //   id: 'billing-setup',
  //   name: 'Billing Setup',
  //   icon: 'las la-file-invoice-dollar',
  //   linkTo: '/settings/billing/services',
  // },
  {
    type: 'solo',
    id: 'medical-records',
    name: 'Medical Records',
    icon: 'las la-file-medical',
    linkTo: '/settings/medical-records/favorite-prescriptions',
  },
];
</script>

<template>
  <div class="flex flex-col justify-between bg-white z-50 p-2 w-[240px]"
    :style="{ height: `${menuHeight}px`, 'margin-top': isMobile ? `${toolbarHeightPx}px` : '' }">
    <div class="flex flex-col gap-2">
      <menu-list :menu="menu" @close="$emit('close')" />
    </div>
  </div>
</template>