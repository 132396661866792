<script setup>
import { ref } from 'vue';
import SettingsMenu from '@/components/settings-menu';
import GlobalSnackbar from '@/components/commons/global-snack';
import UserMenu from '@/components/commons/user-menu';
const drawer = ref(false);
</script>

<template>
  <div data-theme="easyjoey">
    <!-- DIALOGS START -->
    <global-snackbar />
    <!-- DIALOGS END -->

    <div class="drawer lg:drawer-open">
      <input v-model="drawer" id="dashboard-layout-drawer" type="checkbox" class="drawer-toggle" />

      <div class="drawer-content flex flex-col">
        <header
          class="w-full navbar flex border-solid border-1 border-b justify-between items-center sticky top-0 z-10 bg-white h-16">
          <div class="flex-none lg:hidden">
            <label for="dashboard-layout-drawer" aria-label="open sidebar" class="btn btn-square btn-ghost">
              <i class="las la-bars text-2xl"></i>
            </label>
          </div>
          <div class="flex-1">
            <!-- Search -->
          </div>
          <div class="flex-none">
            <user-menu />
          </div>
        </header>
        <div class="flex flex-col">
          <main>
            <NuxtPage />
          </main>
        </div>
      </div>

      <div class="drawer-side border-solid border-1 border-r">
        <label for="dashboard-layout-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
        <header class="navbar flex p-2 justify-between items-center z-10 w-[240px] h-16 header">
          <div class="flex-1 px-2 mx-2">
            <h1 class="text-neutral text-8 font-medium flex items-center gap-2">
              <nuxt-link class="btn btn-sm btn-square btn-ghost" to="/dashboard">
                <i class="la la-arrow-left text-2xl"></i>
              </nuxt-link>
              Settings
            </h1>
          </div>
        </header>
        <SettingsMenu @close="drawer = false;" />
      </div>
    </div>
  </div>
</template>
